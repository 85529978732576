@import "bootstrap/scss/mixins/grid";


// MOSAIC GRID STYLES
.mosaic-grid-bg {
    position: absolute;
    //   background: #fff;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
}

.mosaic-grid-row {
    // background: lightblue;
    @include make-row();

    position: relative;

    >* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
    }

    // responsive columns size exceptions - order is key!

    // example column layout for lg breakpoint to demonstrate the
    // possibility of different responsive layouts.
    // feel free adjust to your needs!

    // @media (max-width: breakpoint-max('lg', $grid-breakpoints)) {

    //   // sm size

    //   .col-1,
    //   .col-2,
    //   .col-3,
    //   .col-4,
    //   .col-5,
    //   .col-6 {
    //     width: 50%;
    //     background: green;
    //   }

    //   .col-7,
    //   .col-8,
    //   .col-9,
    //   .col-10,
    //   .col-11,
    //   .col-12 {
    //     width: 100%;
    //     background: red;
    //   }
    // }

    @media (max-width: breakpoint-max("md", $grid-breakpoints)) {
        > .col {
            flex: 0 0 auto;
        }

        // sm size
        > .col-1,
        > .col-2,
        > .col-3,
        > .col-4,
        > .col-5,
        > .col-6,
        > .col-7,
        > .col-8,
        > .col-9,
        > .col-10,
        > .col-11,
        > .col-12 {
            width: 100%;
        }
    }

    &.mosaic-innergrid-row {
        margin-left: calc(var(--bs-gutter-x) * -0.5);
        margin-right: calc(var(--bs-gutter-x) * -0.5);
    }
}

body.template-layout,
body.mosaic-enabled {
    &.no-portlet-columns {
        .outer-wrapper>.container:not(#content-header) {
            // outline: 1px blue solid;
            max-width: unset;
            padding-left: unset;
            padding-right: unset;

            >.row {
                margin: 0;

                // --bs-gutter-x: 0;
                #portal-column-content {
                    padding: 0;
                }
            }

            .mosaic-grid-row {
                width: 100%;
                padding-right: 0;
                padding-left: 0;
                margin-right: auto;
                margin-left: auto;

                @each $breakpoint, $container-max-width in $container-max-widths {
                    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                        max-width: $container-max-width;

                        &.mosaic-grid-row-fluid-background {
                            padding-left: calc((100% - $container-max-width) * 0.5);
                            padding-right: calc((100% - $container-max-width) * 0.5);
                        }
                    }
                }

                &.mosaic-grid-row-fluid {
                    max-width: unset;
                    padding-left: unset;
                    padding-right: unset;
                    margin-left: 0;
                    margin-right: 0;
                }

                &.mosaic-grid-row-fluid-background {
                    max-width: unset;
                    margin-left: 0;
                    margin-right: 0;
                }

                &.mosaic-innergrid-row {
                    margin-left: calc(var(--bs-gutter-x) * -0.5);
                    margin-right: calc(var(--bs-gutter-x) * -0.5);
                }
            }
        }
    }
}

// todo: decide if still needed
// body.plone-toolbar-left-default,
// body.plone-toolbar-left-expanded {
//   &.no-portlet-columns {
//     .outer-wrapper > .container {
//       .mosaic-grid-row {
//         // @include make-container();
//       }
//     }
//   }
// }

// MEDIA QUERY DEBUG STYLES
// @import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

// @media (max-width: breakpoint-max('xl', $grid-breakpoints)) {
//   body {
//     background: yellow !important;

//     &::before {
//       content: "up to xl";
//       position: sticky;
//       top: 0;
//       left: 0;

//     }
//   }
// }

// @media (max-width: breakpoint-max('lg', $grid-breakpoints)) {
//   body {
//     background: lightblue !important;

//     &::before {
//       content: "up to lg";
//       position: sticky;
//       top: 0;
//       left: 0;
//     }

//   }
// }

// @media (max-width: breakpoint-max('md', $grid-breakpoints)) {
//   body {
//     background: lightgreen !important;

//     &::before {
//       content: "up to md";
//       position: sticky;
//       top: 0;
//       left: 0;
//     }

//   }
// }

// @media (max-width: breakpoint-max('sm', $grid-breakpoints)) {
//   body {
//     background: pink !important;

//     &::before {
//       content: "up to sm";
//       position: sticky;
//       top: 0;
//       left: 0;
//     }

//   }

// }
